<template>
  <div>
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h5 class="mb-0">
            {{
              $t(
                'configurationFundraiseToken.tokenDetails.historicalTokenValues.title'
              )
            }}
          </h5>
        </div>
      </div>
    </div>

    <cp-table
      ref="cpTable"
      get-data-action="configToken/getHistoricalTokenValues"
      :url-params="urlParams"
      :fields="historicalTokenValueFields"
    >
      <template
        slot="tokenValueDate"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.tokenValueDate | dateFilter }}
      </template>
      <template
        slot="tokenValue"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.tokenValue }}
      </template>

      <template
        slot="modificationDate"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.updatedAt | dateFilter }}
      </template>
      <template
        slot="addedBy"
        slot-scope="{ rowData }"
      >
        {{ rowData.item.operatorName }}
      </template>
      <template
        slot="id"
        slot-scope="{ rowData }"
      >
        <span style="float: right">
          &nbsp;
          <Can
            :i="ActionsTypes.UPDATE"
            :a="AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES"
          >
            <b-btn
              v-b-tooltip.hover="true"
              variant="default action-button btn-xs md-btn-flat"
              :title="$t('common.button.edit')"
              @click="toEditModal(rowData.item)"
            >
              <i class="ion ion-md-create" />
            </b-btn>
          </Can>
          <Can
            :i="ActionsTypes.DELETE"
            :a="AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES"
          >
            <span
              class="ion ion-md-trash"
              style="cursor: pointer"
              @click="openDeleteModal(rowData.item)"
            />
          </Can>
        </span>
      </template>
    </cp-table>

    <div class="card-footer">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <Can
            :i="ActionsTypes.CREATE"
            :a="AbilitiesTypes.CONFIGURATION_TOKEN_HISTORICAL_VALUES"
          >
            <b-button
              variant="outline-primary"
              size="sm"
              @click="toCreateModal"
            >
              <i class="ion ion-ios-add-circle-outline" />
              &nbsp;&nbsp;
              {{
                $t(
                  'configurationFundraiseToken.tokenDetails.historicalTokenValues.actions.addTokenValue'
                )
              }}
            </b-button>
          </Can>
        </div>
      </div>
    </div>

    <cp-delete-modal
      ref="cpDeleteAlert"
      :item-info="deleteItem"
      @delete-item="removeItem"
    />

    <cp-historical-token-value-modal
      ref="historicalTokenValueModal"
      :main-currency="mainCurrency"
      :title-data="titleData"
      :modal-data="modalData"
      :mode="modalMode"
      @onSubmit="saveChanges"
    />

    <cp-confirm-modal
      ref="cpConfirmTokenValueOverwrite"
      :title="
        $t(
          'configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.title'
        )
      "
      :ok-text="
        $t(
          'configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.confirm'
        )
      "
      @onOk="overwrite"
    >
      {{
        $t(
          'configurationFundraiseToken.tokenDetails.historicalTokenValues.modal.overwrite.message',
          [
            formatTokenValueDate(overwriteData.tokenValueDate),
            overwriteData.previousTokenValue,
            overwriteData.tokenValue,
          ]
        )
      }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { Can } from '@casl/vue';
import CpHistoricalTokenValueModal from './historical-token-value-modal.vue';
import CpEditableMixin from '~/mixins/editable-invester-details-component';
import staticPathGeneration from '~/mixins/static-path-generation';
import CpTable from '~/components/shared/cp-table';
import CpDeleteModal from '~/components/common/table/deleteModal';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal.vue';
import {
  ActionsTypes,
  AbilitiesTypes,
} from '../../../../../utilities/ability/abilities';

export default {
  name: 'CpHistoricalTokenValues',

  components: {
    Can,
    CpConfirmModal,
    CpTable,
    CpDeleteModal,
    CpHistoricalTokenValueModal,
  },

  props: {
    mainCurrency: {
      type: String,
      default: 'USD',
    },
  },

  mixins: [CpEditableMixin('historicalTokenValues'), staticPathGeneration],

  data() {
    return {
      historicalTokenValueFields: [
        {
          key: 'tokenValueDate',
          i18nKey:
            'configurationFundraiseToken.tokenDetails.historicalTokenValues.table.tokenValueDate',
          tdClass: 'align-middle',
        },
        {
          key: 'tokenValue',
          i18nKey:
            'configurationFundraiseToken.tokenDetails.historicalTokenValues.table.tokenValue',
          tdClass: 'align-middle',
        },
        {
          key: 'modificationDate',
          i18nKey:
            'configurationFundraiseToken.tokenDetails.historicalTokenValues.table.modificationDate',
          tdClass: 'align-middle',
        },
        {
          key: 'addedBy',
          i18nKey:
            'configurationFundraiseToken.tokenDetails.historicalTokenValues.table.addedBy',
          tdClass: 'align-middle',
        },
        {
          key: 'id',
          i18nKey:
            'configurationFundraiseToken.tokenDetails.historicalTokenValues.table.actions',
        },
      ],
      modalData: {},
      modalMode: '',
      titleData: {},
      deleteItem: null,
      urlParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
      },
      overwriteData: {},
      ActionsTypes,
      AbilitiesTypes,
    };
  },
  watch: {
    $route(
      { params: { tokenId: newTokenId } },
      { params: { tokenId: oldTokenId } },
    ) {
      if (newTokenId !== oldTokenId) this.urlParams.tokenId = newTokenId;
    },
  },
  methods: {
    ...mapActions({
      create: 'configToken/createHistoricalTokenValue',
      getById: 'configToken/getHistoricalTokenValue',
      update: 'configToken/updateHistoricalTokenValue',
      delete: 'configToken/deleteHistoricalTokenValue',
      getList: 'configToken/getHistoricalTokenValues',
    }),
    formatTokenValueDate(val) {
      return moment(val).format('ll');
    },
    hideModal() {
      this.$refs.historicalTokenValueModal.$refs.historicalTokenValue.hide();
    },
    showModal() {
      this.$refs.historicalTokenValueModal.$refs.historicalTokenValue.show();
    },
    openDeleteModal(item) {
      this.deleteItem = {
        ...item,
        name: `Token Value for ${this.formatTokenValueDate(
          item.tokenValueDate,
        )}`,
      };
      this.$refs.cpDeleteAlert.$refs.deleteAlert.open();
    },
    toCreateModal() {
      this.titleData = {
        type: this.$t('common.button.add'),
        icon: 'ios-add-circle-outline',
      };

      this.modalData = {
        tokenValue: '',
        tokenValueDate: '',
      };
      this.modalMode = 'create';
      this.showModal();
    },
    toEditModal({ id }) {
      this.titleData = {
        type: this.$t('common.button.edit'),
        icon: 'md-create',
      };
      const { issuerId, tokenId } = this.urlParams;
      this.getById({ issuerId, tokenId, id }).then((data) => {
        this.modalData = data;
        this.modalMode = 'edit';
        this.showModal();
      });
    },
    toOverwriteModal({
      issuerId,
      tokenId,
      tokenValue,
      tokenValueDate,
      id,
      previousTokenValue,
    }) {
      this.overwriteData = {
        issuerId,
        tokenId,
        tokenValue,
        tokenValueDate,
        id,
        previousTokenValue,
      };
      this.$refs.cpConfirmTokenValueOverwrite.show();
    },
    removeItem() {
      const { id } = this.deleteItem;
      const { issuerId, tokenId } = this.urlParams;
      this.delete({ issuerId, tokenId, id }).then(() => {
        this.$refs.cpTable.updateTableData();
        this.$refs.cpDeleteAlert.$refs.deleteAlert.close();
        this.notifyChanges();
        setTimeout(() => {
          this.deleteItem = null;
        }, 1000);
      });
    },
    overwrite() {
      const { issuerId, tokenId, tokenValue, tokenValueDate, id } = this.overwriteData;
      this.update({ issuerId, tokenId, tokenValue, tokenValueDate, id }).then(
        () => {
          this.$refs.cpTable.updateTableData();
          this.hideModal();
          this.notifyChanges();
        },
      );
    },
    saveChanges({ tokenValueDate, tokenValue, id }) {
      const { issuerId, tokenId } = this.urlParams;

      if (this.modalMode === 'create') {
        this.getList({ params: { issuerId, tokenId, tokenValueDate } }).then(
          ({ data }) => {
            const matchingDateTokenValue = data.find(
              resultTokenValue => resultTokenValue.tokenValueDate === tokenValueDate,
            );
            if (!matchingDateTokenValue) {
              this.create({
                issuerId,
                tokenId,
                tokenValue,
                tokenValueDate,
              }).then(() => {
                this.$refs.cpTable.updateTableData();
                this.hideModal();
                this.notifyChanges();
              });
            } else {
              this.toOverwriteModal({
                issuerId,
                tokenId,
                tokenValue,
                tokenValueDate,
                id: matchingDateTokenValue.id,
                previousTokenValue: matchingDateTokenValue.tokenValue,
              });
            }
          },
        );
      } else {
        this.update({ issuerId, tokenId, tokenValue, tokenValueDate, id }).then(
          () => {
            this.$refs.cpTable.updateTableData();
            this.hideModal();
            this.notifyChanges();
          },
        );
      }
      this.hideModal();
    },
    notifyChanges() {
      this.$emit('onChange');
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button {
  border: 0 solid transparent;
  &:hover {
    background: transparent;
  }
  i {
    font-size: 0.894rem;
  }
}
.card-header {
  border-bottom: none;
}
</style>
